
	import { defineComponent, Ref, ref } from 'vue';
	import ValidationAlert from '@/ChildrenComponents/ValidationAlert.vue';
	import LoadingSpinner from '@/ChildrenComponents/LoadingSpinner.vue';
	import BatchUploadModal from '@/ChildrenComponents/Batch/BatchUploadModal.vue';

	import { LookupEnum } from '@/Enums/LookupEnum';
	import { LookupKeys } from '@/Enums/LookupKeys';
	import { PhaseSection } from '@/Enums/PhaseSection';
	import { DrugTestResult } from '@/Enums/DrugTestResult';

	import { IInternalUser } from '@/Models/IInternalUser';
	import { IParticipant } from '@/Models/IParticipant';
	import { IProvider } from '@/Models/IProvider';
	import { IDataTableHeader } from '@/Models/IDataTableHeader';
	import ParticipantSearch from '@/Models/ParticipantSearch';
	import { IBatchEntryForEdit, IBatchEntryParticipantInfo, IBatchEntryValidationResult, IBatchEntryWrapper } from '@/Models/IBatchEntryWrapper';
	import { IBaseLookup } from '@/Models/Lookup/BaseLookup';
	import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
	import { BehaviorResponseLookupWrapper } from '@/Models/Lookup/BehaviorResponseLookupWrapper';
	import { IBehaviorType } from '@/Models/Lookup/IBehaviorType';
	import { IBehaviorResponseType } from '@/Models/Lookup/IBehaviorResponseType';
	import { IPhaseSection } from '@/Models/IPhaseSection';

	import { BatchEntryService } from '@/Services/batch-entry-service';
	import { LookupService } from '@/Services/lookup-service';
	import { ParticipantService } from '@/Services/participant-service';
	import { ProviderService } from '@/Services/provider-service';
	import { DateHelper } from '@/Services/Helper/date-helper';
	import { ValidationHelper } from '@/Services/Helper/validation-helper';
	import { useUserStore } from '@/Services/Store/user-store';
	import { PhaseService } from '@/Services/phase-service';
	import { UploadedBatchEntry } from '@/Services/Helper/bulk-csv-parser';

	export default defineComponent({
		name: 'participant-search',
		components: { 
			LoadingSpinner, 
			ValidationAlert,
			BatchUploadModal,
		},
		setup(): { form: Ref, currentUser: IInternalUser | null } {
			const form = ref(null);
			const userStoreInstance = useUserStore();
			return { form, currentUser: userStoreInstance.$state.cstInternalUser };
		},
		created() {
			const selectedLookups = [
				LookupKeys.CaseManagementStatus,
				LookupKeys.CaseManagementType,
				LookupKeys.CourtAttendanceStatus,
				LookupKeys.CourtAttendanceType,
				LookupKeys.DrugTestMethod,
				LookupKeys.DrugTestLocation,
				LookupKeys.DrugTestSessionType,
				LookupKeys.DrugTestType,
				LookupKeys.DrugTestResult,
				LookupKeys.EmploymentStatus,
				LookupKeys.EmploymentType,
				LookupKeys.GoalStatus,
				LookupKeys.GoalType,
				LookupKeys.MonitoringStatus,
				LookupKeys.MonitoringType,
				LookupKeys.SchoolAttendanceStatus,
				LookupKeys.SchoolStatus,
				LookupKeys.SchoolType,
				LookupKeys.SupervisionStatus,
				LookupKeys.SupervisionType,
				LookupKeys.TreatmentStatus,
				LookupKeys.TreatmentType,
				LookupKeys.Court,
				LookupKeys.County,
				LookupKeys.Gender,
                LookupKeys.AncillaryMeetingType,
                LookupKeys.AncillaryMeetingStatus
			] as LookupKeys[];

			Promise.all([
				LookupService.getLookupsByKey(selectedLookups), 
				LookupService.getBehaviorResponseLookups(), 
				ProviderService.getActiveList(),
				PhaseService.getPhaseSectionsByRole(),
			])
				.then(([
					lookups, 
					behaviorLookups, 
					providers,
					phaseSectionOptions,
				]: [MasterLookupWrapper, BehaviorResponseLookupWrapper, IProvider[], IPhaseSection[]]) => {
					this.resetPage();
					this.lookups = lookups;
					this.behaviorLookups = behaviorLookups;
					
					this.providerList = providers;
					//this always needs to be at the end of the provider list
					this.providerList.push({ providerId: 1, name: "Other" } as IProvider);
					
					this.phaseSectionList = phaseSectionOptions;
				}).finally(() => this.isLoading = false);
		},
		data () {
			return {
				lookups: {} as MasterLookupWrapper,
				behaviorLookups: new BehaviorResponseLookupWrapper(),
				searchResults: [] as IParticipant[],
				searchDto: new ParticipantSearch(),
				searchValidationResults: [] as string[],
				validationMessages: [] as string[],
				saveResults: null as IBatchEntryValidationResult | null,

				isLoading: true,
				isLoadingResults: false,
				isSaving: false,
				isFormValid: true,
				showParticipantAddedMessage: false,
				showBulkDataUploadModal: false,

				headers: [
					{ title: "Name", key: "name", sortable: true, align: "start" },
					{ title: "Add", key: "actions", sortable: false }
				] as IDataTableHeader[],
				resultHeaders: [
					{ title: "Participant Name", key: "name", sortable: true, align: "start" },
					{ title: "Status", key: "status", sortable: true, align: "start" },
                    { title: "Phase", key: "phase", sortable: true, align: "start" },
                    { title: "Phase Start", key: "phaseStartDate", sortable: true, align:"start" },
                    { title: "Phase End", key: "phaseEndDate", sortable: true, align:"start" },
					{ title: "Details", key: "details", sortable: false, align: "start" },
				] as IDataTableHeader[],
				searchOptions: [
					{ key: "firstName", name: "First Name", type: "text" },
					{ key: "lastName", name: "Last Name", type: "text" },
					{ key: "birthDate", name: "Date of Birth", type: "date" },
					{ key: "ssn", name: "SSN", type: "text" },
					{ key: "participantNumber", name: "Participant Number", type: "text" },
					{ key: "court", name: "Court", type: "select", lookupKey: LookupKeys.Court },
					{ key: "county", name: "County", type: "select", lookupKey: LookupKeys.County },
					{ key: "gender", name: "Sex", type: "select", lookupKey: LookupKeys.Gender },
				],

				entry: {} as IBatchEntryForEdit,
				requiredRules: ValidationHelper.requiredRules,
				multiSelectRules: ValidationHelper.atLeastOneRules,
				providerList: [] as IProvider[],
				behaviorTypeFilter: "both",
				crTypeList: [{ value: "InCustody", title: "In Custody" }, { value: "InResidential", title: "In Residential" }, { value: "Suspension", title: "Suspension" }],
				phaseSectionList: [] as { id: PhaseSection; description: string }[],
                partSearchDialog: false,
			}
		},
		methods: {
			getLookupList(key: LookupKeys): IBaseLookup[] {
				return this.lookups.lookupLists[key] ?? [];
			},
			clearSearch() {
				this.searchDto = new ParticipantSearch();
				this.searchDto.activePhaseOnly = true;
				this.searchValidationResults = [];
				this.searchResults = [];
			},
			getResults() {
				this.searchValidationResults = this.searchDto.validate();
				if (this.searchValidationResults.length == 0) {
					this.isLoadingResults = true;
					ParticipantService.searchActives(this.searchDto).then(response => this.searchResults = response).then(() => this.isLoadingResults = false);
				}
			},
			selectParticipant(participant: IParticipant) {
				if (this.entry.participants.find(x => x.participantId == participant.participantId) == undefined) {
					// fill default values which should populate when specific entry types are selected
					participant.drugTestResultId = participant.drugTestResultId ?? DrugTestResult.Negative;
					participant.courtAttendanceStatusId = participant.courtAttendanceStatusId ?? this.statusList.find(sl => sl.description == 'Attended')?.id ?? 1;
				
					// map the participants into the batch entry table entries and sort them
					this.entry.participants.push(participant as IBatchEntryParticipantInfo);
					this.entry.participants.sort((x, y) => {
						if (x.lastName == y.lastName) {
							return x.firstName == y.firstName ? 0 : (x.firstName > y.firstName ? 1 : -1);
						}
						return x.lastName > y.lastName ? 1 : -1;
					});
				}
				this.showParticipantAddedMessage = true;
			},
			onDataUploadClick(): void {
				this.showBulkDataUploadModal = true;
			},
			/**
			 * The method responsible for uploading the CSV bulk data. Hits the bulk entry list endpoint and
			 * mirrors the regular submit workflow by putting validation results into the saveResults object
			 * @param {UploadedBatchEntry[]} uploadedData a set of data that has been completely moved into
			 * the UploadedBatchEntry type. All that's left before submission is to attach the required phaseSection
			 * id value and cast it to an IBatchEntryWrapper object.
			 */
			onCsvDataUpload(uploadedData: UploadedBatchEntry[]): void {
				this.showBulkDataUploadModal = false;
				this.isSaving = true;

				const submissionReadyData: IBatchEntryWrapper[] = uploadedData.map(ube => ({
						...ube.data,
						// decoding based on the type to encode that type as a PhaseSectionEnum value
						phaseSection: ube.type == "drug test" ? 3 : 10,
					} as IBatchEntryWrapper));

				// we've now attached the last required piece of data for this to be read as a valid batch entry wrapper
				// so we can cast it to indicate that fact
				BatchEntryService.submitBatchEntryList(submissionReadyData)
					.then((res) => {
						this.saveResults = res;	
					}).finally(() => {
						this.isSaving = false;
					});
			},
			removeParticipant(participantId: number) {
				this.entry.participants = this.entry.participants.filter(x => x.participantId != participantId);
			},
			resetPage() {
				this.entry = { participants: [] as IBatchEntryParticipantInfo[], behaviorIds: [] as number[], responseIds: [] as number[] } as IBatchEntryForEdit;
				this.clearSearch();
				this.saveResults = null;
			},
			prepareForSave() {
				this.entry.startDate = DateHelper.standardizeDate(this.entry.startDateForEdit);
				this.entry.endDate = DateHelper.standardizeDate(this.entry.endDateForEdit);
                if (!this.entry.endDate) this.entry.endDate = this.entry.startDate;
				this.entry.datePerformed = DateHelper.standardizeDate(this.entry.datePerformedForEdit);
				this.entry.targetEndDate = DateHelper.standardizeDate(this.entry.targetEndDateForEdit);

				if (!this.entry.behaviorIds.includes(LookupEnum.Other)) {
					this.entry.behaviorTypeOther = "";
				}
				if (!this.entry.responseIds.includes(LookupEnum.Other)) {
					this.entry.behaviorResponseTypeOther = "";
				}
			},
			save() {
                if (!this.entry.endDateForEdit) this.entry.endDateForEdit = this.entry.startDateForEdit;
				this.form.validate().then(() => {
					if (!this.isFormValid || this.entry.participants.length == 0) return;

					this.isSaving = true;
					this.prepareForSave();
					BatchEntryService.submitBatchEntry(this.entry as IBatchEntryWrapper).then(results => this.saveResults = results).finally(() => this.isSaving = false);
				});
			},
			formatDate(date: Date): string {
				return DateHelper.formatForEdit(date);
			},
		},
		computed: {
			participantHeaders(): IDataTableHeader[] {
				let results = [
					{ title: "Participant Number", key: "participantId", sortable: true, align: "start" },
					{ title: "First Name", key: "firstName", sortable: true, align: "start" },
					{ title: "Last Name", key: "lastName", sortable: true, align: "start" },
					{ title: "Current Phase", key: "phase", sortable: true, align:"start" },
					{ title: "Phase Start", key: "phaseStartDate", sortable: true, align:"start" },
					{ title: "Phase End", key: "phaseEndDate", sortable: true, align:"start" },
					{ title: "Drug Test Result", key: "drugTest", sortable: false, align: "start" },
					{ title: "Attendance Status", key: "courtAttendance", sortable: false, align: "start" },
					{ title: "Remove", key: "actions", sortable: false }
				] as IDataTableHeader[];

				if (this.entry.phaseSection != PhaseSection.DrugTest) {
					results = results.filter(x => x.key != "drugTest");
				}

				if (this.entry.phaseSection != PhaseSection.CourtAttendance) {
					results = results.filter(r => r.key != "courtAttendance");
				}

				return results;
			},
			typeList(): IBaseLookup[] {
				switch (this.entry.phaseSection) {
					case PhaseSection.CaseManagement: return this.getLookupList(LookupKeys.CaseManagementType);
					case PhaseSection.CourtAttendance: return this.getLookupList(LookupKeys.CourtAttendanceType);
					case PhaseSection.ElectronicMonitoring: return this.getLookupList(LookupKeys.MonitoringType);
					case PhaseSection.Employment: return this.getLookupList(LookupKeys.EmploymentType);
					case PhaseSection.Goal: return this.getLookupList(LookupKeys.GoalType);
					case PhaseSection.School: return this.getLookupList(LookupKeys.SchoolType);
					case PhaseSection.Supervision: return this.getLookupList(LookupKeys.SupervisionType);
					case PhaseSection.Treatment: return this.getLookupList(LookupKeys.TreatmentType);
                    case PhaseSection.AncillaryMeeting: return this.getLookupList(LookupKeys.AncillaryMeetingType);
					default: return [] as IBaseLookup[];
				}
			},
			statusList(): IBaseLookup[] {
				switch (this.entry.phaseSection) {
					case PhaseSection.CaseManagement: return this.getLookupList(LookupKeys.CaseManagementStatus);
					case PhaseSection.CourtAttendance: return this.getLookupList(LookupKeys.CourtAttendanceStatus);
					case PhaseSection.ElectronicMonitoring: return this.getLookupList(LookupKeys.MonitoringStatus);
					case PhaseSection.Employment: return this.getLookupList(LookupKeys.EmploymentStatus);
					case PhaseSection.Goal: return this.getLookupList(LookupKeys.GoalStatus);
					case PhaseSection.BehaviorResponse: return this.behaviorLookups.statuses;
					case PhaseSection.School: return this.getLookupList(LookupKeys.SchoolStatus);
					case PhaseSection.Supervision: return this.getLookupList(LookupKeys.SupervisionStatus);
					case PhaseSection.Treatment: return this.getLookupList(LookupKeys.TreatmentStatus);
                    case PhaseSection.AncillaryMeeting: return this.getLookupList(LookupKeys.AncillaryMeetingStatus);
					default: return [] as IBaseLookup[];
				}
			},
			behaviorTypeList(): IBehaviorType[] {
				if (this.behaviorTypeFilter == "positive") {
					return this.behaviorLookups.behaviorTypes.filter(x => x.isPositive || x.isPositive === null || this.entry.behaviorIds.includes(x.id));
				}
				if (this.behaviorTypeFilter == "negative") {
					return this.behaviorLookups.behaviorTypes.filter(x => !x.isPositive || x.isPositive === null || this.entry.behaviorIds.includes(x.id));
				}
				return this.behaviorLookups.behaviorTypes;
			},
			responseTypeList(): IBehaviorResponseType[] {
				if (this.behaviorTypeFilter == "positive") {
					return this.behaviorLookups.responseTypes.filter(x => x.showForPositive || this.entry.responseIds.includes(x.id));
				}
				if (this.behaviorTypeFilter == "negative") {
					return this.behaviorLookups.responseTypes.filter(x => x.showForNegative || this.entry.responseIds.includes(x.id));
				}
				return this.behaviorLookups.responseTypes;
			},
			attendanceList(): IBaseLookup[] {
				return this.getLookupList(LookupKeys.SchoolAttendanceStatus);
			},
			drugTestSessionTypes(): IBaseLookup[] {
				return this.getLookupList(LookupKeys.DrugTestSessionType);
			},
			drugTestMethods(): IBaseLookup[] {
				return this.getLookupList(LookupKeys.DrugTestMethod);
			},
			drugTestTypes(): IBaseLookup[] {
				return this.getLookupList(LookupKeys.DrugTestType);
			},
			drugTestLocations(): IBaseLookup[] {
				return this.getLookupList(LookupKeys.DrugTestLocation);
			},
			drugTestResults(): IBaseLookup[] {
				return this.getLookupList(LookupKeys.DrugTestResult);
			},
			isBehaviorResponse(): boolean {
				return this.entry.phaseSection == PhaseSection.BehaviorResponse;
			},
			isCustodyResidential(): boolean {
				return this.entry.phaseSection == PhaseSection.CustodyResidential;
			},
			isDrugTest(): boolean {
				return this.entry.phaseSection == PhaseSection.DrugTest;
			},
			isEmployment(): boolean {
				return this.entry.phaseSection == PhaseSection.Employment;
			},
			isSchool(): boolean {
				return this.entry.phaseSection == PhaseSection.School;
			},
			isSupervision(): boolean {
				return this.entry.phaseSection == PhaseSection.Supervision;
			},
			isTreatment(): boolean {
				return this.entry.phaseSection == PhaseSection.Treatment;
			},
            isCourtAttendance(): boolean {
				return this.entry.phaseSection == PhaseSection.CourtAttendance;
			},
			showProviderOther(): boolean {
				return this.isTreatment && this.entry.providerId == 1;
			},
			showLocationOther(): boolean {
				return this.isDrugTest && this.entry.drugTestLocationId == LookupEnum.Other;
			},
			showOtherBehaviorType(): boolean {
				return this.isBehaviorResponse && this.entry.behaviorIds.includes(LookupEnum.Other);
			},
			showOtherResponseType(): boolean {
				return this.isBehaviorResponse && this.entry.responseIds.includes(LookupEnum.Other);
			},
			buttonText(): string {
				return this.isSaving ? "Submitting..." : "Submit";
			},
		},
	});
