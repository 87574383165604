import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sectionHeader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParticipantHeader = _resolveComponent("ParticipantHeader")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_CourtAttendance = _resolveComponent("CourtAttendance")!
  const _component_BehaviorResponses = _resolveComponent("BehaviorResponses")!
  const _component_CaseManagement = _resolveComponent("CaseManagement")!
  const _component_AncillaryMeeting = _resolveComponent("AncillaryMeeting")!
  const _component_Supervision = _resolveComponent("Supervision")!
  const _component_Treatment = _resolveComponent("Treatment")!
  const _component_DrugTestList = _resolveComponent("DrugTestList")!
  const _component_ElectronicMonitoring = _resolveComponent("ElectronicMonitoring")!
  const _component_Employment = _resolveComponent("Employment")!
  const _component_School = _resolveComponent("School")!
  const _component_Goals = _resolveComponent("Goals")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_CustodyResidential = _resolveComponent("CustodyResidential")!
  const _component_AlternateWorkflowSubmission = _resolveComponent("AlternateWorkflowSubmission")!
  const _component_DocumentGenerator = _resolveComponent("DocumentGenerator")!
  const _component_WorkflowHistory = _resolveComponent("WorkflowHistory")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.headerData && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_ParticipantHeader, {
          key: 0,
          data: _ctx.headerData
        }, null, 8, ["data"]))
      : _createCommentVNode("", true),
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.pageHeaderText), 1),
    (_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_SingleErrorMessage, { errorMessage: _ctx.errorMessage }, null, 8, ["errorMessage"]),
    _createVNode(_component_ValidationAlert, { validationResults: _ctx.validationMessages }, null, 8, ["validationResults"]),
    (!_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_v_expansion_panels, {
          key: 2,
          multiple: "",
          modelValue: _ctx.openPanels,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.openPanels) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Phase Info "),
                    _createVNode(_component_v_chip, {
                      class: "ml-auto",
                      color: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Days in Phase: " + _toDisplayString(_ctx.calculatedPhaseDays), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, { ref: "infoForm" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { md: "3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  disabled: !_ctx.canEditRolePermission,
                                  type: "date",
                                  label: "Start Date",
                                  modelValue: _ctx.phase.startDateForEdit,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phase.startDateForEdit) = $event))
                                }, null, 8, ["disabled", "modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { md: "3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  disabled: !_ctx.canEditRolePermission,
                                  type: "date",
                                  label: "End Date",
                                  modelValue: _ctx.phase.endDateForEdit,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phase.endDateForEdit) = $event)),
                                  rules: _ctx.endDateRules
                                }, null, 8, ["disabled", "modelValue", "rules"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { md: "3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  disabled: !_ctx.canEditRolePermission,
                                  type: "date",
                                  label: "Projected Completion Date",
                                  modelValue: _ctx.phase.completionDateForEdit,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.phase.completionDateForEdit) = $event))
                                }, null, 8, ["disabled", "modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 512),
                    (_ctx.isSaveAllowed)
                      ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { align: "center" }, {
                              default: _withCtx(() => [
                                _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                                  class: "mx-2 mb-4",
                                  color: "success",
                                  onClick: _ctx.savePhase,
                                  disabled: _ctx.isSaving
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.noteButtonText), 1)
                                  ]),
                                  _: 1
                                }, 8, ["onClick", "disabled"])), [
                                  [_directive_ripple]
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Court Attendance")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_CourtAttendance, {
                      lookups: _ctx.lookups,
                      phaseId: _ctx.phase.phaseId,
                      isSaveAllowed: _ctx.isSaveAllowed,
                      isDeleteAllowed: _ctx.isDeleteAllowed
                    }, null, 8, ["lookups", "phaseId", "isSaveAllowed", "isDeleteAllowed"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Responses to Behavior")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_BehaviorResponses, {
                      phaseId: _ctx.phase.phaseId,
                      isSaveAllowed: _ctx.isSaveAllowed,
                      isDeleteAllowed: _ctx.isDeleteAllowed
                    }, null, 8, ["phaseId", "isSaveAllowed", "isDeleteAllowed"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Case Management")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_CaseManagement, {
                      lookups: _ctx.lookups,
                      phaseId: _ctx.phase.phaseId,
                      isSaveAllowed: _ctx.isSaveAllowed,
                      isDeleteAllowed: _ctx.isDeleteAllowed
                    }, null, 8, ["lookups", "phaseId", "isSaveAllowed", "isDeleteAllowed"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Ancillary Meetings")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_AncillaryMeeting, {
                      lookups: _ctx.lookups,
                      phaseId: _ctx.phase.phaseId,
                      isSaveAllowed: _ctx.isSaveAllowed,
                      isDeleteAllowed: _ctx.isDeleteAllowed
                    }, null, 8, ["lookups", "phaseId", "isSaveAllowed", "isDeleteAllowed"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Supervision")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Supervision, {
                      lookups: _ctx.lookups,
                      phaseId: _ctx.phase.phaseId,
                      isSaveAllowed: _ctx.isSaveAllowed,
                      isDeleteAllowed: _ctx.isDeleteAllowed
                    }, null, 8, ["lookups", "phaseId", "isSaveAllowed", "isDeleteAllowed"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Treatment")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Treatment, {
                      lookups: _ctx.lookups,
                      phaseId: _ctx.phase.phaseId,
                      isSaveAllowed: _ctx.isSaveAllowed,
                      isAddAllowed: _ctx.isUserProvider,
                      isDeleteAllowed: _ctx.isDeleteAllowed
                    }, null, 8, ["lookups", "phaseId", "isSaveAllowed", "isAddAllowed", "isDeleteAllowed"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Drug Tests")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_DrugTestList, {
                      phaseId: _ctx.phase.phaseId,
                      isSaveAllowed: _ctx.isSaveAllowed,
                      isDeleteAllowed: _ctx.isDeleteAllowed
                    }, null, 8, ["phaseId", "isSaveAllowed", "isDeleteAllowed"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Electronic Monitoring")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElectronicMonitoring, {
                      lookups: _ctx.lookups,
                      phaseId: _ctx.phase.phaseId,
                      isSaveAllowed: _ctx.isSaveAllowed,
                      isDeleteAllowed: _ctx.isDeleteAllowed
                    }, null, 8, ["lookups", "phaseId", "isSaveAllowed", "isDeleteAllowed"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Employment")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Employment, {
                      lookups: _ctx.lookups,
                      phaseId: _ctx.phase.phaseId,
                      caseId: _ctx.phase.caseId,
                      isSaveAllowed: _ctx.isSaveAllowed,
                      isDeleteAllowed: _ctx.isDeleteAllowed
                    }, null, 8, ["lookups", "phaseId", "caseId", "isSaveAllowed", "isDeleteAllowed"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("School")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_School, {
                      lookups: _ctx.lookups,
                      phaseId: _ctx.phase.phaseId,
                      caseId: _ctx.phase.caseId,
                      isSaveAllowed: _ctx.isSaveAllowed,
                      isDeleteAllowed: _ctx.isDeleteAllowed
                    }, null, 8, ["lookups", "phaseId", "caseId", "isSaveAllowed", "isDeleteAllowed"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Goals")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Goals, {
                      lookups: _ctx.lookups,
                      phaseId: _ctx.phase.phaseId,
                      isSaveAllowed: _ctx.isSaveAllowed,
                      isDeleteAllowed: _ctx.isDeleteAllowed
                    }, null, 8, ["lookups", "phaseId", "isSaveAllowed", "isDeleteAllowed"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Notes")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, { ref: "noteForm" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_textarea, {
                                  modelValue: _ctx.phase.notes,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.phase.notes) = $event)),
                                  label: "Note Text",
                                  disabled: !_ctx.isSaveAllowed
                                }, null, 8, ["modelValue", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 512),
                    (_ctx.isSaveAllowed)
                      ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { align: "center" }, {
                              default: _withCtx(() => [
                                _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                                  class: "mx-2 mb-4",
                                  color: "success",
                                  onClick: _ctx.savePhase,
                                  disabled: _ctx.isSaving
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.noteButtonText), 1)
                                  ]),
                                  _: 1
                                }, 8, ["onClick", "disabled"])), [
                                  [_directive_ripple]
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("In Custody / In Residential / Suspension")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_component_CustodyResidential, {
                      key: 'comp-cust-res-' + _ctx.pageKey,
                      lookups: _ctx.lookups,
                      phaseId: _ctx.phase.phaseId,
                      isSaveAllowed: _ctx.isSaveAllowed,
                      isDeleteAllowed: _ctx.isDeleteAllowed
                    }, null, 8, ["lookups", "phaseId", "isSaveAllowed", "isDeleteAllowed"]))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.canEditRolePermission)
              ? (_openBlock(), _createBlock(_component_v_expansion_panel, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Workflow Status")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel_text, null, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_component_AlternateWorkflowSubmission, {
                          key: 'comp-alt-wfsubmit-' + _ctx.pageKey,
                          caseId: _ctx.phase.caseId,
                          parentId: _ctx.phase.phaseId,
                          workflowInstanceId: _ctx.phase.workflowInstanceId,
                          type: _ctx.workflowType,
                          onSuccess: _ctx.workflowSubmitSuccess,
                          onError: _ctx.workflowSubmitError
                        }, null, 8, ["caseId", "parentId", "workflowInstanceId", "type", "onSuccess", "onError"]))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Document Generation")
                  ]),
                  _: 1
                }),
                (_ctx.phase != null)
                  ? (_openBlock(), _createBlock(_component_v_expansion_panel_text, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DocumentGenerator, {
                          documentParentId: _ctx.phase!.phaseId,
                          infillProp: _ctx.infillVars,
                          documentParentTypeId: _ctx.DocumentParentType.Phase,
                          treatmentProgramId: _ctx.caseData.treatmentProgramId
                        }, null, 8, ["documentParentId", "infillProp", "documentParentTypeId", "treatmentProgramId"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Workflow History")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_component_WorkflowHistory, {
                      key: 'comp-wfhistory-' + _ctx.pageKey,
                      workflowInstanceId: _ctx.phase.workflowInstanceId
                    }, null, 8, ["workflowInstanceId"]))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_snackbar, {
      color: "success",
      modelValue: _ctx.showSuccessMessage,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showSuccessMessage) = $event)),
      timeout: "2000"
    }, {
      default: _withCtx(() => [
        _createTextVNode("Saved successfully!")
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}