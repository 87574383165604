import { IBatchEntryParticipantInfo, IBatchEntryWrapper } from "@/Models/IBatchEntryWrapper";
import { LookupService } from "../lookup-service";
import { MasterLookupWrapper } from "@/Models/Lookup/MasterLookupWrapper";
import { LookupKeys } from "@/Enums/LookupKeys";
import { IDrugTestType } from "@/Models/Lookup/IDrugTestType";
import { IDrugTestLocation } from "@/Models/Lookup/IDrugTestLocation";
import { IDrugTestSessionType } from "@/Models/Lookup/IDrugTestSessionType";
import { IDrugTestMethod } from "@/Models/Lookup/IDrugTestMethod";
import { IDrugTestResult } from "@/Models/Lookup/IDrugTestResult";
import { ITreatmentStatus } from "@/Models/Lookup/ITreatmentStatus";
import { ProviderService } from "../provider-service";
import { IProvider } from "@/Models/IProvider";
import { ITreatmentType } from "@/Models/Lookup/ITreatmentType";

// ValidationError is a type which provides parsing error information which may be relevant to the user
// as they correct a parsing error. It is not emitted when there is something which prevents parsing
// entirely. Currently, this only leads to 'Unexpected Type Errors' being held in the Validation Error
// object, but in the future other errors such as 'Unknown Id' could be encapsulated as well.
export type ValidationError = {
	participantId: number;
	columnName: PlaintextHeader;
	rawValue: string;
	error: string;
}

/**
 * getFormattedValidationError converts a validationError object into a nicely formatted,
 * human readable string suitable for display. It should be used only after the correct error
 * has been identified, as filtering, sorting, and modifying errors is simpler with the 
 * ValidationError type objects
 * @param {ValidationError} err the error to display
 * @returns {string} A human readable version of the parameterized error
 */
export const getFormattedValidationError = (err: ValidationError): string => {
	return `${err.error}: Participant Id ${err.participantId} in Column '${err.columnName}' found text entry = '${err.rawValue}'`;
}

// UploadedRecordType is a limited string set which indicates the various types of records which
// can be processed by this bulk csv parsing system. It is also used to decode switching logic
// to display components based on the parsed type of their object
export type UploadedRecordType = "drug test" | "treatment";

// UploadedBatchEntry is the primary object emitted by this parser. It contains metadata which is
// used for visualization and integration with Vuetify (i.e. id to enable sorting in tables) as
// well as entry status information (i.e. validation errors). If isValid is true, then the data in
// the data prop can be safely cast to an IBatchEntryWrapper and submitted to the bulk entry endpoints
export type UploadedBatchEntry = {
	id: number;
	data: IBatchEntryWrapper; // contains the IBatchEntryParticipantInfo in the participants prop
	type: UploadedRecordType;
	validationErrors: ValidationError[];
	isValid: boolean;
}

/**
 * parseCsvFile accepts a file object and will leverage the methods existing in
 * this file to parse that file into a set of objects which allow for data correction
 * and submission to the batch endpoint. Headers must be included as the first row
 * in the file and will be used to decode which type of data is being uploaded automatically.
 * This is the main entry point into the CSV parser, and the only method which should
 * be exported and externally consumed.
 * @param {File} file the file which should be parsed as a CSV
 * @returns {Promise<UploadedBatchEntry[]>} a promise which resolves to a list
 * of UploadedBatchEntry objects where each object is a single row from the CSV.
 */
export const parseCsvFile = (file: File): Promise<UploadedBatchEntry[]> => {
	return new Promise((res, rej) => {
		Promise.all([
				LookupService.getLookupsByKey([
					LookupKeys.DrugTestType,
					LookupKeys.DrugTestLocation,
					LookupKeys.DrugTestSessionType,
					LookupKeys.DrugTestMethod,
					LookupKeys.DrugTestResult,
					LookupKeys.TreatmentType,
					LookupKeys.TreatmentStatus,
				]),
				ProviderService.getActiveList(),
				file.text(),
			])
			.then((
				[lookups, providers, text]: [MasterLookupWrapper, IProvider[], string]
			) => {
				let lines = text.split("\n")
				const headers = lines[0].split(",").map(h => h.trim());
				lines = lines.slice(1);
			
				if (lines.length < 2) {
					rej(new Error('didnt find multiple rows in csv'));
					return;
				}

				const preparedLookups: Record<string, any[]> = {};

				preparedLookups['DrugTestType'] = lookups.lookupLists[LookupKeys.DrugTestType];
				preparedLookups['DrugTestLocation'] = lookups.lookupLists[LookupKeys.DrugTestLocation];
				preparedLookups['DrugTestSessionType'] = lookups.lookupLists[LookupKeys.DrugTestSessionType];
				preparedLookups['DrugTestMethod'] = lookups.lookupLists[LookupKeys.DrugTestMethod];
				preparedLookups['DrugTestResult'] = lookups.lookupLists[LookupKeys.DrugTestResult];
				preparedLookups['TreatmentType'] = lookups.lookupLists[LookupKeys.TreatmentType];
				preparedLookups['TreatmentStatus'] = lookups.lookupLists[LookupKeys.TreatmentStatus];
				preparedLookups['Providers'] = providers;
			
				const parsedData = [] as UploadedBatchEntry[];
				lines.forEach(line => {
					const [lineData, err] = parseCsvLine(line, headers, preparedLookups);
					if (err != null) {
						rej(err);
						return;
					}

					parsedData.push(lineData!);
				});
			
				res(parsedData);
		});
	})	
}

// some magical indexing setups which say that this object may be indexed by strings but imposes no constraints
// on what type of value can be stored in each indexed object other than requiring a `participants` property
// which has an identical indexing setup
// https://www.typescriptlang.org/docs/handbook/2/objects.html#index-signatures
// objects of this type are intermediary/in-progress construction of an IBatchEntryWrapper which will get embedded
// in an UploadedBatchEntryObject
type ParsedLineDataObject = {
	[index: string]: any, 
	participants: {
		[index: string]: any, 
	}[],
}

// A ValidBatchEntry object is an alias which is largely compatible with the UploadedBatchEntry
// object, but enforces the constraint that the validationErrors are dropped. This could be done
// manually, but in the lifecycle of our application, we use this as an intermediary object to
// represent an UploadedBatchEntry that is ready for submission (i.e. has no errors), but that
// has not yet been cast into only the IBatchEntryWrapper for submission. If passing of parsed
// objects is necessary outside of the correction editor, this type should be used to verify
// the parsed objects are in a valid state
export type ValidBatchEntry = {
	data: IBatchEntryWrapper; // contains the IBatchEntryParticipantInfo in the participants prop
	type: UploadedRecordType;
	validationErrors: null;
}

// A ParsingResult will have one but not both of the entries as null. If the error
// term (i.e. the second term) is null, then the data object is valid. Otherwise,
// the data object (i.e. the first term) is null and the Error contains context
// on what has gone wrong. ParsingErrors bubble up to the entrypoint and are then
// rejected in the parseCsvFile promise
type ParsingResult = [(UploadedBatchEntry | null), (Error | null)];

// PlaintextHeader is a type alias used to indicate when a parameterized string must correlate
// with a specific header defiend by a HeaderMapRecord object in this file. They're currently
// used externally to match individual errors with specific props on the object when creating
// error tooltips
export type PlaintextHeader = string;
type KeytextHeader = keyof IBatchEntryWrapper | keyof IBatchEntryParticipantInfo;
type ParenttextHeader = "IBatchEntryWrapper" | "IBatchEntryParticipantInfo";
type ExpectedType = "lookup" | "lookup[]" | "string" | "number" | "number[]" | "date";
type HeaderMapRecord = [PlaintextHeader, KeytextHeader, ParenttextHeader, ExpectedType];
type HeaderMapRecords = [PlaintextHeader, KeytextHeader, ParenttextHeader, ExpectedType][];


// We want to manually define our header map records to provide all of the needed
// information to map columns addressed by a plaintext header into the appropriate
// places on our parsed data object
const commonHeaders: HeaderMapRecords = [
	["Participant Id", "participantId", "IBatchEntryParticipantInfo", "number"], 
	["First Name", "firstName", "IBatchEntryParticipantInfo", "string"],
	["Last Name", "lastName", "IBatchEntryParticipantInfo", "string"], 
];
const drugTestUniqueHeaders: HeaderMapRecords = [
	["Type", "drugTestTypeIds", "IBatchEntryWrapper", "lookup[]"],
	["Test Date", "datePerformed", "IBatchEntryWrapper", "date"],
	["Location", "drugTestLocationId", "IBatchEntryWrapper", "lookup"],
	["Session Type", "drugTestSessionTypeId", "IBatchEntryWrapper", "lookup"], 
	["Method", "drugTestMethodId", "IBatchEntryWrapper", "lookup"],
	["Test Performed By", "performedBy", "IBatchEntryWrapper", "string"],
	["Drug Test Result", "drugTestResultId", "IBatchEntryParticipantInfo", "lookup"]
];
const treatmentUniqueHeaders: HeaderMapRecords = [
	["Type", "typeId", "IBatchEntryWrapper", "lookup"],
	["Start Date", "startDate", "IBatchEntryWrapper", "date"],
	["End Date", "endDate", "IBatchEntryWrapper", "date"],
	["Status", "statusId", "IBatchEntryWrapper", "lookup"],
	["Provider", "providerId", "IBatchEntryWrapper", "lookup"],
	["Minutes", "minutes", "IBatchEntryWrapper", "number"]
];

/**
 * given a list of valid header map records, extract the one with header matching the parameterized header label
 * @param {PlaintextHeader} header The user facing string naming the column
 * @param {HeaderMapRecords} options A set of potential column data which will be searched for the column name 
 * matching the `header` parameter
 * @returns Either the matching HeaderMapRecord object or undefined if the specified column name isn't found in
 * the specified options list
 */
const getObjectRecordFromHeader = (header: PlaintextHeader, options: HeaderMapRecords): (HeaderMapRecord | undefined) => {
	return options.find((o) => o[0] == header);
}

/**
 * parseCsvLine takes a line from a CSV and the split headers (and assumes they are in the same order) and returns
 * the csv data parsed into a result object
 * @param {string} line a row from the csv which assumes no modifications to the string
 * @param {string[]} headers a set of strings representing the plain text headers to the csv file
 * @returns the tuple (UploadedBatchEntry?, Error?) where one is null and the other is not
 */
const parseCsvLine = (line: string, headers: string[], lookups: Record<string, any[]>): ParsingResult => {

	// based on which headers are present, we dispatch to the appropriate entry type sub-method
	// which will validate all of the required headers are present and do the actual parsing
	// and type checking
	if (headers.includes(drugTestUniqueHeaders[1][0])) {
		return parseCsvLineDrugTest(line, headers, lookups);
	} else if (headers.includes(treatmentUniqueHeaders[1][0])) {
		return parseCsvLineTreatment(line, headers, lookups);
	} else {
		return [null, new Error("did not find expected columns")];
	}

}

const parseCsvLineDrugTest = (line: string, headers: string[], lookups: Record<string, any[]>): ParsingResult => {
	const requiredHeaders = commonHeaders.concat(drugTestUniqueHeaders);

	// ensure all required headers are present
	for (let i = 0; i < requiredHeaders.length; i++) {
		if (!headers.includes(requiredHeaders[i][0])) {
			return [null, new Error(`required headers not present in csv headers: ${requiredHeaders[i][0]}`)];
		}
	}

	const columns = line.split(",").map(c => c.trim());

	// parse out the raw string objects into the well typed objects in the ParsedLineDataObject container 
	const [dataObject, validationErrors, errors] = parseLineObject(columns, headers, requiredHeaders, lookups);
	if (errors != null) {
		return [null, errors]
	}

	// decode the parsed results into our domain specific objects, which contain the editable and submittable
	// records for usage on the batch entry page
	const uploadedLineItem: UploadedBatchEntry = {
		// we cast this because the IBEW model doesn't support the variable properties based on which type of data is 
		// being uploaded on the front end. it does support them appropriately on the backend, which makes this cast
		// a required step
		id: dataObject?.participants[0].participantId ?? 0,
		data: (dataObject as unknown) as IBatchEntryWrapper, 
		type: "drug test",
		validationErrors: validationErrors,
		isValid: validationErrors.length == 0,
	}

	return [uploadedLineItem, null]
}

const parseCsvLineTreatment = (line: string, headers: string[], lookups: Record<string, any[]>): ParsingResult => {
	const requiredHeaders = commonHeaders.concat(treatmentUniqueHeaders);
	
	for (let i = 0; i < requiredHeaders.length; i++) {
		if (!headers.includes(requiredHeaders[i][0])) {
			return [{} as UploadedBatchEntry, new Error("required headers not present in csv headers")]
		}
	}
	
	// now that we've validated all headers are present, set up the parsing objects to track our parsed data
	const columns = line.split(",").map(c => c.trim());

	// parse out the raw string objects into the well typed objects in the ParsedLineDataObject container 
	const [dataObject, validationErrors, errors] = parseLineObject(columns, headers, requiredHeaders, lookups);
	if (errors != null) {
		return [null, errors]
	}

	// decode the parsed results into our domain specific objects, which contain the editable and submittable
	// records for usage on the batch entry page
	const uploadedLineItem: UploadedBatchEntry = {
		// we cast this because the IBEW model doesn't support the variable properties based on which type of data is 
		// being uploaded on the front end. it does support them appropriately on the backend, which makes this cast
		// a required step
		id: dataObject?.participants[0].participantId ?? 0,
		data: (dataObject as unknown) as IBatchEntryWrapper, 
		type: "treatment",
		validationErrors: validationErrors,
		isValid: validationErrors.length == 0,
	}

	return [uploadedLineItem, null]

}

const parseLineObject = (columns: string[], headers: string[], requiredHeaders: HeaderMapRecords, lookups: Record<string, any[]>): [(ParsedLineDataObject | null), ValidationError[], (Error | null)] => {
	const dataObject: ParsedLineDataObject = { participants: [{}], };
	const validationErrors: ValidationError[] = [];

	// for each column, go through and parse the data in each column, putting it in the data Object
	for (let i = 0; i < headers.length; i++) {
		// decode metadata about the specific record we're working with which gives us data like expected type and
		// expected property name for inserting into our data object
		const objectRecord: HeaderMapRecord | undefined = getObjectRecordFromHeader(headers[i], requiredHeaders);
		if (!objectRecord) {
			return [null, [], new Error(`unable to find object record for header=${headers[i]}`)];
		}

		const objectParent: ParenttextHeader = objectRecord[2];
		const objectKey: keyof IBatchEntryWrapper 
			| keyof IBatchEntryParticipantInfo = objectRecord[1];
		
		// objectValue is now well typed if it exists as a requirement of parseLineItem
		const objectValue: (string | number | number[] | Date | null) = parseLineItem(columns[i], objectRecord, lookups);

		if (!objectValue) {
			validationErrors.push({
				participantId: dataObject.participants[0].participantId ?? -1,
				columnName: headers[i],
				rawValue: columns[i],
				error: "Unexpected type error"
			});
			continue;
		}

		// now we can guarantee that objectValue has a well typed and parsed value that can be safely insert into the
		// named property on the wrapper object

		if (objectParent == "IBatchEntryWrapper") {
			// we're manually constructing an IBatchEntryWrapper object one prop at a time, so we're saying each time
			// that it is able to use the key we've extracted to index the object even if it isn't already on the object
			(dataObject)[objectKey as keyof typeof dataObject] = objectValue;
		} else if (objectParent == "IBatchEntryParticipantInfo") {
			// same as above here, but building an IBatchEntryParticipantInfo which lives within the data object
			(dataObject['participants'][0])[objectKey as keyof (typeof dataObject.participants[0])] = objectValue;
		} else {
			return [null, [], new Error("encountered unexpected data parent type")];
		}
	}

	return [dataObject, validationErrors, null];
}

// TYPED PARSING HELPERS
// Each of these will attempt to convert a column into the type specified both in the function name
// and in the return type. If the column value cannot be parsed, then the return result will be null
// We include the HeaderMapRecord pre-emptively to allow for further constraints based on parsing
// specific data fields if necessary
const parseLineItemAsNumber = (columnVal: string): (number | null) => {
	const parsedNumber = new Number(columnVal);
	if (isNaN(parsedNumber.valueOf())) {
		return null;
	}
	return parsedNumber.valueOf();
};

const parseLineItemAsNumberList = (columnVal: string): (number[] | null) => {
	const num = parseLineItemAsNumber(columnVal);
	return num ? [num] : null;
}

const parseLineItemAsDate = (columnVal: string): (Date | null) => {
	const parsedDate = new Date(columnVal);
	if (isNaN(parsedDate.getTime())) {
		return null;
	}
	return parsedDate;
};

const parseLineItemAsString = (columnVal: string): (string | null) => {
	return columnVal;
};

const parseLineItemAsLookup = (columnVal: string, typeData: HeaderMapRecord, lookups: Record<string, any[]>): (number | null) => {
	switch (typeData[1]) {
		case "drugTestTypeIds":
			return (lookups['DrugTestType'].filter((dt: IDrugTestType) => dt.description == columnVal)[0] as IDrugTestType)?.id ?? null;
		case "drugTestLocationId":
			return (lookups['DrugTestLocation'].filter((dt: IDrugTestLocation) => dt.description == columnVal)[0] as IDrugTestLocation)?.id ?? null;
		case "drugTestSessionTypeId":
			return (lookups['DrugTestSessionType'].filter((dt: IDrugTestSessionType) => dt.description == columnVal)[0] as IDrugTestSessionType)?.id ?? null;
		case "drugTestMethodId":
			return (lookups['DrugTestMethod'].filter((dt: IDrugTestMethod) => dt.description == columnVal)[0] as IDrugTestMethod)?.id ?? null;
		case "drugTestResultId":
			return (lookups['DrugTestResult'].filter((dt: IDrugTestResult) => dt.description == columnVal)[0] as IDrugTestResult)?.id ?? null;
		case "typeId":
			return (lookups['TreatmentType'].filter((t: ITreatmentType) => t.description == columnVal)[0] as ITreatmentType)?.id ?? null;
		case "statusId":
			return (lookups['TreatmentStatus'].filter((t: ITreatmentStatus) => t.description == columnVal)[0] as ITreatmentStatus)?.id ?? null;
		case "providerId":
			return (lookups['Providers'].filter((p: IProvider) => p.name == columnVal)[0] as IProvider)?.id ?? null;
	}
	return null;
};

const parseLineItemAsLookupList = (columnVal: string, typeData: HeaderMapRecord, lookups: Record<string, any[]>): (number[] | null) => {
	const ids = columnVal.split(",").map(e => {
		const listItemName = e.trim();
		return parseLineItemAsLookup(listItemName, typeData, lookups);
	}).reduce((acc: number[], curr: (number | null)): number[] => {
		if (curr) {
			acc.push(curr);
		}
		return acc;
	}, [] as number[]);
	
	if (ids.length == 0) {
		return null;
	}

	return ids;
};

const parseLineItem = (columnVal: string, typeData: HeaderMapRecord, lookups: Record<string, any[]>): (Date | string | number | number[] | null) => {
	switch (typeData[3]) {
		case "lookup":
			return parseLineItemAsLookup(columnVal, typeData, lookups);
		case "lookup[]":
			return parseLineItemAsLookupList(columnVal, typeData, lookups);
		case "date":
			return parseLineItemAsDate(columnVal);		
		case "number":
			return parseLineItemAsNumber(columnVal);
		case "number[]":
			return parseLineItemAsNumberList(columnVal);	
		case "string":
			return parseLineItemAsString(columnVal);
		default:
			return null;
	}
}