export enum Role {
	StateAdministrator = 1,
	StateUser = 2,
	Provider = 3,
	Judge = 4,
    Coordinator = 5,
	CaseManager = 6,
	ProbationSupervisor = 7,
	PeerSupportSpecialist = 8,
	DataEntryUser = 9,
}