
import { getFormattedValidationError, PlaintextHeader, UploadedBatchEntry, ValidationError } from '@/Services/Helper/bulk-csv-parser';
import { defineComponent, PropType, ref, Ref } from 'vue';
import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
import LoadingSpinner from '../LoadingSpinner.vue';
import { IProvider } from '@/Models/IProvider';
import { DateHelper } from '@/Services/Helper/date-helper';
import { ValidationHelper } from '@/Services/Helper/validation-helper';
import { LookupKeys } from '@/Enums/LookupKeys';

export type TreatmentBatchEntry = UploadedBatchEntry & {type: "treatment"};

export default defineComponent({
	name: "correction-editor-treatment",
	emits: [
		// we don't well type these because we need to use the composition api to expose
		// a ref to the form to allow for validation, and the defineEmits compiler macro
		// doesn't play nicely with the composition API as of this version
		"close", // void
		"update", // TreatmentBatchEntry
	],
	props: {
		providedParsedData: {
			type: Object as PropType<TreatmentBatchEntry>,
			required: true,
		},
		showDialog: {
			type: Boolean,
			required: true,
		},
		lookups: {
			type: Object as PropType<MasterLookupWrapper>,
			required: true,
		},
		providers: {
			type: Object as PropType<IProvider[]>,
			required: true,
		},
	},
	components: {
		LoadingSpinner
	},
	setup(): { form: Ref } {
		const form = ref(null);
		return { form };
	},
	created() {
		this.isLoading = true;
		this.visible = this.showDialog;
		this.parsedData = this.providedParsedData;

		this.startDateEntry = DateHelper.formatForEdit(this.parsedData.data.startDate);
		this.endDateEntry = DateHelper.formatForEdit(this.parsedData.data.endDate);

		this.isLoading = false;
	},
	mounted() {
		this.form.validate();
	},
	data: () => ({
		visible: false,
		isLoading: true,
		isValid: false,
		isUpdating: false,

		// we get this data provided from a required prop and then watch it to update in place as necessary
		parsedData: {} as TreatmentBatchEntry,
		startDateEntry: "",
		endDateEntry: "",

		requiredRules: ValidationHelper.requiredRules,
		LookupKeys,
	}),
	methods: {
		getErrorTooltip(header: PlaintextHeader): string {
			const rowValidationError: ValidationError | undefined = this.parsedData.validationErrors.find(err => err.columnName == header);
			return rowValidationError ? getFormattedValidationError(rowValidationError) : "";
		},
		onCloseDialog(): void {
			if (confirm("Would you like to close? Updates will not be saved.")) {
				this.$emit("close");
			}
		},
		onUpdate(): void {
			this.isUpdating = true;
			this.validate();
			this.parsedData.data.startDate = DateHelper.formatFromEdit(this.startDateEntry);
			this.parsedData.data.endDate = DateHelper.formatFromEdit(this.endDateEntry);

			if (this.isValid) {
				this.parsedData.validationErrors = [];
				this.parsedData.isValid = true;
			}

			this.$emit("update", this.parsedData);
		},
		validate() {
			this.form.validate()	
		}
	},
	computed: {
		updateButtonText(): string {
			if (this.isUpdating) {
				return "Updating...";
			}
			return "Update";
		}
	},
	watch: {
		providedParsedData(newVal: TreatmentBatchEntry): void {
			this.parsedData = newVal;
			this.startDateEntry = DateHelper.formatForEdit(this.parsedData.data.startDate);
			this.endDateEntry = DateHelper.formatForEdit(this.parsedData.data.endDate);
			this.form.validate();
		},
		showDialog(newVal: boolean): void {
			this.visible = newVal;
			this.form.validate();
		},
	}
})
