import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IncorrectPermissions = _resolveComponent("IncorrectPermissions")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_SingleErrorMessage = _resolveComponent("SingleErrorMessage")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_OutcomeEventTable = _resolveComponent("OutcomeEventTable")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_ValidationAlert = _resolveComponent("ValidationAlert")!
  const _component_SuccessAlert = _resolveComponent("SuccessAlert")!
  const _component_v_form = _resolveComponent("v-form")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isPermissionDenied)
      ? (_openBlock(), _createBlock(_component_v_container, {
          key: 0,
          fluid: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IncorrectPermissions)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.isPermissionDenied && _ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 1 }))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_v_form, {
          key: 2,
          onSubmit: _withModifiers(_ctx.submit, ["prevent"])
        }, {
          default: _withCtx(() => [
            (_ctx.errorMessage != '')
              ? (_openBlock(), _createBlock(_component_SingleErrorMessage, {
                  key: 0,
                  errorMessage: _ctx.errorMessage
                }, null, 8, ["errorMessage"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_1, [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.rawData.income,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.rawData.income) = $event)),
                            label: "Income"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.rawData.incomeSource,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.rawData.incomeSource) = $event)),
                            label: "Income Source"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_checkbox, {
                            modelValue: _ctx.rawData.receivedWellfare,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.rawData.receivedWellfare) = $event)),
                            label: "Wellfare"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_checkbox, {
                            modelValue: _ctx.rawData.receivedFoodstamps,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.rawData.receivedFoodstamps) = $event)),
                            label: "Foodstamps"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_checkbox, {
                            modelValue: _ctx.rawData.paidTaxes,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.rawData.paidTaxes) = $event)),
                            label: "Paid Taxes"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, {
                            modelValue: _ctx.rawData.criminalJusticeStatus,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.rawData.criminalJusticeStatus) = $event)),
                            items: _ctx.criminalJusticeStatusOpts,
                            "item-value": "id",
                            "item-title": "description",
                            label: "Criminal Justice Status"
                          }, null, 8, ["modelValue", "items"])
                        ]),
                        _: 1
                      }),
                      (_ctx.rawData.paidTaxes)
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 0,
                            cols: "12",
                            md: "3"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                modelValue: _ctx.rawData.taxAmount,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.rawData.taxAmount) = $event)),
                                label: "Tax Amount"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.rawData.paidTaxes)
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 1,
                            cols: "12",
                            md: "3"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                modelValue: _ctx.rawData.taxYear,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.rawData.taxYear) = $event)),
                                label: "Tax Year"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, {
                            modelValue: _ctx.rawData.residenceStatus,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.rawData.residenceStatus) = $event)),
                            items: _ctx.residenceOpts,
                            "item-value": "id",
                            "item-title": "description",
                            label: "Residence Status"
                          }, null, 8, ["modelValue", "items"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, {
                            modelValue: _ctx.rawData.housingStatus,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.rawData.housingStatus) = $event)),
                            items: _ctx.housingStatusOpts,
                            "item-value": "id",
                            "item-title": "description",
                            label: "Housing Status"
                          }, null, 8, ["modelValue", "items"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_OutcomeEventTable, {
                            routeId: _ctx.routeId,
                            outcomeId: _ctx.closureOutcome.outcomeId,
                            canEditPermission: _ctx.canEditPermission,
                            maySave: _ctx.canEditPermission,
                            mayDelete: _ctx.canEditPermission
                          }, null, 8, ["routeId", "outcomeId", "canEditPermission", "maySave", "mayDelete"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, { align: "center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "2"
                      }, {
                        default: _withCtx(() => [
                          _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                            class: "mx-2 mb-4",
                            color: "success",
                            disabled: _ctx.isSaving,
                            type: "submit"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Save")
                            ]),
                            _: 1
                          }, 8, ["disabled"])), [
                            [_vShow, _ctx.canEditPermission],
                            [_directive_ripple]
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_spacer)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ValidationAlert, { validationResults: _ctx.validationResults }, null, 8, ["validationResults"]),
                  _createVNode(_component_SuccessAlert, {
                    feedbackMessages: _ctx.successResults,
                    timeout: 3000
                  }, null, 8, ["feedbackMessages"])
                ]))
          ]),
          _: 1
        }, 8, ["onSubmit"]))
      : _createCommentVNode("", true)
  ], 64))
}