
import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
import { getFormattedValidationError, PlaintextHeader, UploadedBatchEntry, ValidationError } from '@/Services/Helper/bulk-csv-parser';
import { ValidationHelper } from '@/Services/Helper/validation-helper';
import { defineComponent, PropType, ref, Ref } from 'vue';
import LoadingSpinner from '../LoadingSpinner.vue';
import { DateHelper } from '@/Services/Helper/date-helper';
import { LookupKeys } from '@/Enums/LookupKeys';
export type DrugTestBatchEntry = UploadedBatchEntry & {type: "drug test"};

export default defineComponent({
	name: "correction-editor-drug-test",
	emits: [
		// we don't well type these because we need to use the composition api to expose
		// a ref to the form to allow for validation, and the defineEmits compiler macro
		// doesn't play nicely with the composition API as of this version
		"close", // void
		"update", // DrugTestBatchEntry object
	],
	props: {
		providedParsedData: {
			type: Object as PropType<DrugTestBatchEntry>,
			required: true,
		},
		showDialog: {
			type: Boolean,
			required: true,
		},
		lookups: {
			type: Object as PropType<MasterLookupWrapper>,
			required: true,
		}
	},
	components: {
		LoadingSpinner,
	},
	setup(): { form: Ref } {
		const form = ref(null);
		return { form };
	},
	created() {
		this.isLoading = true;
		this.visible = this.showDialog;
		this.parsedData = this.providedParsedData;

		this.testDateEntry = DateHelper.formatForEdit(this.parsedData.data.datePerformed);

		this.isLoading = false;
	},
	mounted() {
		this.form.validate();
	},
	data: () => ({
		visible: false,
		isValid: false,
		isLoading: false,

		// we get this data provided from a required prop and then watch it to update in place as necessary
		parsedData: {} as DrugTestBatchEntry,
		testDateEntry: "",

		requiredRules: ValidationHelper.requiredRules,
		LookupKeys,
	}),
	methods: {
		getErrorTooltip(header: PlaintextHeader): string {
			const rowValidationError: ValidationError | undefined = this.parsedData.validationErrors.find(err => err.columnName == header);
			return rowValidationError ? getFormattedValidationError(rowValidationError) : "";
		},
		onCloseDialog(): void {
			if (confirm("Would you like to close? Updates will not be saved.")) {
				this.$emit("close");
			}
		},
		onUpdate(): void {
			this.validate();
			this.parsedData.data.datePerformed = DateHelper.formatFromEdit(this.testDateEntry);

			if (this.isValid) {
				this.parsedData.validationErrors = [];
				this.parsedData.isValid = true;
			}

			this.$emit("update", this.parsedData);
		},
		validate() {
			this.form.validate();
		}
	},
	computed: {
	},
	watch: {
		providedParsedData(newVal: DrugTestBatchEntry): void {
			this.parsedData = newVal;
			this.testDateEntry = DateHelper.formatForEdit(this.parsedData.data.datePerformed);
		},
		showDialog(newVal: boolean): void {
			this.visible = newVal;
			this.form.validate();
		}
	}
})
