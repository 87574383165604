
import { defineComponent } from 'vue';

import { LookupKeys } from '@/Enums/LookupKeys';
import { MenuItemTypes } from '@/Enums/MenuItemTypes';
import { ComponentType } from '@/Enums/ComponentType';

import { IOutcome, IOutcomeEntry } from '@/Models/IOutcomes';
import { IHousingStatus } from '@/Models/Lookup/IHousingStatus';
import { IResidence } from '@/Models/Lookup/IResidence';
import { ICriminalJusticeStatus } from '@/Models/Lookup/Outcomes/ICriminalJusticeStatus';
import { MasterLookupWrapper } from '@/Models/Lookup/MasterLookupWrapper';
import ParticipantPageDto from '@/Models/ParticipantPageDto';
import { IInternalUser } from '@/Models/IInternalUser';

import { LookupService } from '@/Services/lookup-service';
import { OutcomeService } from '@/Services/outcome-service';
import { InternalUserService } from '@/Services/internal-user-service';
import { loadParticipantDataRequirePhase } from '@/Services/Helper/loader-helper';
import { useUserStore } from '@/Services/Store/user-store';
import { doesUserRoleHaveComponentViewPermissions } from '@/Services/Helper/component-permissions-helper';

import OutcomeEventTable from '@/ChildrenComponents/Outcomes/OutcomeEventTable.vue';
import ValidationAlert from '../ValidationAlert.vue';
import SuccessAlert from '../SuccessAlert.vue';
import SingleErrorMessage from '../Participant/Common/SingleErrorMessage.vue';
import LoadingSpinner from '../LoadingSpinner.vue';
import IncorrectPermissions from '../IncorrectPermissions.vue';

export default defineComponent({
    name: 'outcome-collection-component',
    components: {
        OutcomeEventTable, 
        ValidationAlert, 
        SuccessAlert, 
        SingleErrorMessage, 
        LoadingSpinner,
		IncorrectPermissions,
    },
    emits: ['save-complete'],
    props: {
        routeId: {
            type: Number,
            required: true
        },
        canEditPermission: {
            type: Boolean,
            required: true,
        }
    },
	setup(): { currentUser: IInternalUser | null } {
		const userStoreInstance = useUserStore();
		const currentUser = userStoreInstance.$state.cstInternalUser;
		return {
			currentUser,
		};
	},
    created() {

		if (!this.currentUser || !doesUserRoleHaveComponentViewPermissions(this.currentUser.attachedRoles ?? [], ComponentType.OutcomeSummary)) {
			this.isPermissionDenied = true;
			return;
		}

        const lookupKeys = [
            LookupKeys.HousingStatus,
            LookupKeys.Residence,
            LookupKeys.CriminalJusticeStatus,
        ];

        Promise.all([
                LookupService.getLookupsByKey(lookupKeys), 
                this.isCaseRoute ? OutcomeService.getClosureOutcomeByCaseId(this.routeId) : OutcomeService.getClosureOutcomeByPhaseId(this.routeId),
                !this.isCaseRoute ? loadParticipantDataRequirePhase(MenuItemTypes.Phase, this.routeId) : (({} as unknown) as ParticipantPageDto),
            ])
            .then(([lookups, outcome, optCaseInfo]: [MasterLookupWrapper, IOutcome[], ParticipantPageDto]) => {
                this.housingStatusOpts = lookups.lookupLists[LookupKeys.HousingStatus] as IHousingStatus[] ?? [];
                this.residenceOpts = lookups.lookupLists[LookupKeys.Residence] as IResidence[] ?? [];
                this.criminalJusticeStatusOpts = lookups.lookupLists[LookupKeys.CriminalJusticeStatus] as ICriminalJusticeStatus[] ?? [];

                if (!this.isCaseRoute) {
                    this.closureOutcome.outcomeId = optCaseInfo?.case?.caseId || this.routeId;
                }

                if (!outcome || outcome.length === 0) {
                    throw new Error("No Outcomes Available for this Case.");
                }

                this.closureOutcome = outcome[0];
                this.rawData = {
                    income: this.closureOutcome.income,
                    incomeSource: this.closureOutcome.incomeSource,
                    receivedWellfare: this.closureOutcome.wellfare,
                    receivedFoodstamps: this.closureOutcome.foodStamps,
                    paidTaxes: this.closureOutcome.paidTaxes,
                    criminalJusticeStatus: this.closureOutcome.criminalJusticeStatus,
                    taxAmount: this.closureOutcome.taxesPaid,
                    taxYear: this.closureOutcome.taxYear,
                    housingStatus: this.closureOutcome.housingStatus,
                    residenceStatus: this.closureOutcome.residenceStatus,
                }
            })
            .catch((err) => this.errorMessage = err.toString())
            .finally(() => this.isLoading = false);
    },
    data() {
        return {
            isLoading: true,
            isSaving: false,
            isCaseRoute: this.$route.path.includes("/case/") || this.$route.path.includes("/case-closure/") as boolean,
			isPermissionDenied: false,
            
			closureOutcome: {} as IOutcome,
            rawData: {      
                income: "",
                incomeSource: "",
                receivedWellfare: false,
                receivedFoodstamps: false,
                paidTaxes: true,
                criminalJusticeStatus: undefined,
                taxAmount: "",
                taxYear: (new Date()).getFullYear() - 1,
                residenceStatus: undefined,
                housingStatus: undefined,
            } as IOutcomeEntry,

            criminalJusticeStatusOpts: [] as ICriminalJusticeStatus[],
            residenceOpts: [] as IResidence[],
            housingStatusOpts: [] as IHousingStatus[],

            validationResults: [] as string[],
            errorMessage: "" as string,
            successResults: [] as string[],
        }
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        async submit(_event: any) {
            InternalUserService.getCurrentCstUser().then((u) => {
                const dto = {
                    caseId: this.closureOutcome.caseId,
                    outcomeId: this.closureOutcome.outcomeId,
                    id: this.closureOutcome.id,
                    reportType: this.closureOutcome.reportType,
                    income: this.rawData.income,
                    incomeSource: this.rawData.incomeSource,
                    housingStatus: this.rawData.housingStatus,
                    residenceStatus: this.rawData.residenceStatus,
                    criminalJusticeStatus: this.rawData.criminalJusticeStatus,
                    wellfare: this.rawData.receivedWellfare,
                    foodStamps: this.rawData.receivedFoodstamps,
                    paidTaxes: this.rawData.paidTaxes,
                    taxesPaid: this.rawData.taxAmount,
                    taxBurden: 0,
                    taxYear: this.rawData.taxYear,
                    isNew: false,

                    createDate: (new Date()),
                    modifyDate: (new Date()),
                    createUserId: u.internalUserId,
                    modifyUserId: u.internalUserId,
                } as IOutcome;

                OutcomeService.save(dto)
                    .then((res) => {
                        if (!res.isValid) {
                            this.validationResults = res.messages;
                        } else {
                            this.successResults = ["Successfully saved closure outcome results."]
                        }
                    }).then(() => {
                        this.$emit('save-complete');
                    });
            });
        },
    }
});
